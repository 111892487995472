// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Container from '@mui/material/Container';
// import InputLabel from '@mui/material/InputLabel';
// import Link from '@mui/material/Link';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';

// import { visuallyHidden } from '@mui/utils';
// import { styled } from '@mui/material/styles';

// const StyledBox = styled('div')(({ theme }) => ({
//     alignSelf: 'center',
//     width: '100%',
//     height: 400,
//     marginTop: theme.spacing(8),
//     borderRadius: theme.shape.borderRadius,
//     outline: '6px solid',
//     outlineColor: 'hsla(220, 25%, 80%, 0.2)',
//     border: '1px solid',
//     borderColor: theme.palette.grey[200],
//     boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
//     backgroundImage: `url(${'/static/screenshots/material-ui/getting-started/templates/dashboard.jpg'})`,//THIS IS WHERE MY IMAGE NEEDS TO GO
//     backgroundSize: 'cover',
//     [theme.breakpoints.up('sm')]: {
//         marginTop: theme.spacing(10),
//         height: 700,
//     },
//     ...theme.applyStyles('dark', {
//         boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
//         backgroundImage: `url(${'/static/screenshots/material-ui/getting-started/templates/dashboard-dark.jpg'})`, //THIS IS WHERE MY IMAGE NEEDS TO GO
//         outlineColor: 'hsla(220, 20%, 42%, 0.1)',
//         borderColor: theme.palette.grey[700],
//     }),
// }));

// export default function Hero() {
//     return (
//         <Box
//             id="hero"
//             sx={(theme) => ({
//                 width: '100%',
//                 backgroundRepeat: 'no-repeat',
//                 backgroundImage:
//                     'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
//                 ...theme.applyStyles('dark', {
//                     backgroundImage:
//                         'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
//                 }),
//             })}
//         >
//             <Container
//                 sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                     pt: { xs: 14, sm: 20 },
//                     pb: { xs: 8, sm: 12 },
//                 }}
//             >
//                 <Stack
//                     spacing={2}
//                     useFlexGap
//                     sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
//                 >
//                     <Typography
//                         variant="h1"
//                         sx={{
//                             display: 'flex',
//                             flexDirection: { xs: 'column', sm: 'row' },
//                             alignItems: 'center',
//                             fontSize: 'clamp(3rem, 10vw, 3.5rem)',
//                         }}
//                     >
//                         Welcome to&nbsp;
//                         <Typography
//                             component="span"
//                             variant="h1"
//                             sx={(theme) => ({
//                                 fontSize: 'inherit',
//                                 color: 'primary.main',
//                                 ...theme.applyStyles('dark', {
//                                     color: 'primary.light',
//                                 }),
//                             })}
//                         >
//                             Squad Control
//                         </Typography>
//                     </Typography>
//                     <Typography
//                         sx={{
//                             textAlign: 'center',
//                             color: 'text.secondary',
//                             width: { sm: '100%', md: '80%' },
//                         }}
//                     >
//                         Running your schools' rugby program should not be a difficult task. Let us handle the admin. You focus on the coaching
//                     </Typography>
//                     <Typography
//                         sx={{
//                             textAlign: 'center',
//                             color: 'text.secondary',
//                             width: { sm: '100%', md: '80%' },
//                         }}
//                     >
//                         Developed and run by ex-players and coaches. A Proudly South-African Product
//                     </Typography>
//                     <Stack
//                         direction={{ xs: 'column', sm: 'row' }}
//                         spacing={1}
//                         useFlexGap
//                         sx={{ pt: 2, width: { xs: '100%', sm: '350px' } }}
//                     >
//                         <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
//                             Email
//                         </InputLabel>
//                         <TextField
//                             id="email-hero"
//                             hiddenLabel
//                             size="small"
//                             variant="outlined"
//                             aria-label="Enter your email address"
//                             placeholder="Your email address"
//                             fullWidth
//                             slotProps={{
//                                 htmlInput: {
//                                     autoComplete: 'off',
//                                     'aria-label': 'Enter your email address',
//                                 },
//                             }}
//                         />
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             size="small"
//                             sx={{ minWidth: 'fit-content' }}
//                         >
//                             Start now
//                         </Button>
//                     </Stack>
//                     <Typography
//                         variant="caption"
//                         color="text.secondary"
//                         sx={{ textAlign: 'center' }}
//                     >
//                         By clicking &quot;Start now&quot; you agree to our&nbsp;
//                         <Link href="#" color="primary">
//                             Terms & Conditions
//                         </Link>
//                         .
//                     </Typography>
//                 </Stack>
//                 <StyledBox id="image" />
//             </Container>
//         </Box>
//     );
// }


import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const imageUrls = [
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/eg2.jpg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/kloof1.jpg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/ErmeloSpanfoto.jpg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/eg3.jpg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/HTSMiddelburgSpanfoto.jpg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/Menlo1.jpg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/eg4.jpeg',
    // 'https://squadcontrolblobstorage.blob.core.windows.net/images/PHSSpanfoto.jpg',


    //NEW IMAGES
    'https://squadcontrolblobstorage.blob.core.windows.net/images/SCPoster.jpg',
    'https://squadcontrolblobstorage.blob.core.windows.net/images/SCSlide1.jpg',
    'https://squadcontrolblobstorage.blob.core.windows.net/images/SCSlide2.jpg',
    'https://squadcontrolblobstorage.blob.core.windows.net/images/SCSlide3.jpg'
];

// const StyledBox = styled('div')(({ theme, backgroundImage }) => ({
//     alignSelf: 'center',
//     width: '102%',
//     height: 400,
//     marginTop: theme.spacing(8),
//     borderRadius: theme.shape.borderRadius,
//     outline: '6px solid',
//     outlineColor: 'hsla(220, 25%, 80%, 0.2)',
//     border: '1px solid',
//     borderColor: theme.palette.grey[200],
//     boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
//     backgroundImage: `url(${backgroundImage})`, // Use the current background image
//     backgroundSize: 'cover',
//     [theme.breakpoints.up('sm')]: {
//         marginTop: theme.spacing(10),
//         height: 700,
//     },
//     ...theme.applyStyles('dark', {
//         boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
//         backgroundImage: `url(${backgroundImage})`, // Use the current background image for dark mode
//         outlineColor: 'hsla(220, 20%, 42%, 0.1)',
//         borderColor: theme.palette.grey[700],
//     }),
// }));

const StyledBox = styled('div')(({ theme, backgroundImage }) => ({
    alignSelf: 'center',
    width: '102%', // Ensure it fits within the viewport
    height: 'auto', // Let the height adjust based on content or aspect ratio
    aspectRatio: '16 / 9', // Maintain the image aspect ratio
    marginTop: theme.spacing(8),
    borderRadius: theme.shape.borderRadius,
    outline: '6px solid',
    outlineColor: 'hsla(220, 25%, 80%, 0.2)',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover', // Ensure the image fills the container
    backgroundPosition: 'center', // Center the image
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(10),
        height: 700,
    },
    [theme.breakpoints.down('sm')]: {
        height: 'auto', // Auto adjust height for mobile screens
        aspectRatio: '16 / 9', // Maintain aspect ratio
      //  backgroundSize: 'contain', // Ensure the image is fully visible
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
        backgroundImage: `url(${backgroundImage})`,
        outlineColor: 'hsla(220, 20%, 42%, 0.1)',
        borderColor: theme.palette.grey[700],
    }),
}));



export default function Hero() {
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
    const navigate = useNavigate();
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // 3 seconds interval for image rotation

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                ...theme.applyStyles('dark', {
                    backgroundImage:
                        'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
                }),
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack
                    spacing={2}
                    useFlexGap
                    sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                        }}
                    >
                        Welcome to&nbsp;
                        <Typography
                            component="span"
                            variant="h1"
                            sx={(theme) => ({
                                fontSize: 'inherit',
                                color: 'primary.main',
                                ...theme.applyStyles('dark', {
                                    color: 'primary.light',
                                }),
                            })}
                        >
                            Squad Control
                        </Typography>
                    </Typography>
                    <Typography
                        variant='h6'
                        sx={{
                            textAlign: 'center',
                            color: 'text.secondary',
                            width: { sm: '100%', md: '80%' },
                        }}
                    >
                        A Platform for rugby coaches in South Africa.
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: 'text.secondary',
                            width: { sm: '100%', md: '80%' },
                        }}
                    >
                        Running your schools' rugby program should not be a difficult task. Let us handle the admin. You focus on the coaching
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: 'text.secondary',
                            width: { sm: '100%', md: '80%' },
                        }}
                    >
                        Developed and run by ex-players and coaches. A Proudly South-African Product
                    </Typography>
                    {/* <Stack
                        direction={{ xs: 'column', sm: 'row', lg: 'row', alignItems: 'center' }}
                        spacing={1}
                        useFlexGap
                        sx={{ pt: 2, width: { xs: '100%', sm: '350px' } }}
                    >
                         <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
                            Email
                        </InputLabel>
                        <TextField
                            id="email-hero"
                            hiddenLabel
                            size="small"
                            variant="outlined"
                            aria-label="Enter your email address"
                            placeholder="Your email address"
                            fullWidth
                            slotProps={{
                                htmlInput: {
                                    autoComplete: 'off',
                                    'aria-label': 'Enter your email address',
                                },
                            }}
                        /> 
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => navigate('/contactUs')}
                            sx={{ minWidth: 'fit-content' }}
                        >
                            Start now
                        </Button>
                    </Stack> */}
                    <Stack
                        direction={{ xs: 'column', sm: 'row', lg: 'row' }}
                        alignItems="center"  // Ensure alignment to center items
                        justifyContent="center"  // Centers the button horizontally
                        spacing={1}
                        useFlexGap
                        sx={{ pt: 2, width: { xs: '100%', sm: '350px' }, mx: 'auto' }} // Add 'mx: auto' to center the stack in its container
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => navigate('/contactUs')}
                            sx={{ minWidth: 'fit-content' }}
                        >
                            Start now
                        </Button>
                    </Stack>

                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ textAlign: 'center' }}
                    >
                        By clicking &quot;Start now&quot; you agree to our&nbsp;
                        <Link href="#" color="primary">
                            Terms & Conditions
                        </Link>
                        .
                    </Typography>
                </Stack>
                <StyledBox id="image" backgroundImage={imageUrls[currentImageIndex]} />
            </Container>
        </Box>
    );
}
